/**
 * Vuex store mutation types constants.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */

export const ADD_ITEMS = 'ADD_ITEMS'
export const ADD_ITEMS_TO_FRONT = 'ADD_ITEMS_TO_FRONT'
export const ADD_EVENTS = 'ADD_EVENTS'
export const ADD_EVENT_EXCEPTION = 'ADD_EVENT_EXCEPTION'
export const ADD_NOTE_TO_CALL = 'ADD_NOTE_TO_CALL'
export const ADD_TO_FILTER = 'ADD_TO_FILTER'
export const UPDATE_TO_FILTER = 'UPDATE_TO_FILTER'
export const ADD_NOTE_TO_PAGE = 'ADD_NOTE_TO_PAGE'
export const ADD_RECENT_CALL = 'ADD_RECENT_CALL'
export const COMPLETE_CALL = 'COMPLETE_CALL'
export const DISMISS_CALL = 'DISMISS_CALL'
export const ADD_NOTE_EVENT = 'ADD_NOTE_EVENT'
export const UPDATE_CALL = 'UPDATE_CALL'
export const ADD_CALL_TYPE_COUNT = 'ADD_CALL_TYPE_COUNT'
export const LOGOUT = 'LOGOUT'
export const REMOVE_ALL_RECENT_CALLS = 'REMOVE_ALL_RECENT_CALLS'
export const REMOVE_BY_ID = 'REMOVE_BY_ID'
export const REMOVE_DATE_RANGE_FILTER = 'REMOVE_DATE_RANGE_FILTER'
export const REMOVE_EVENT_EXCEPTION = 'REMOVE_EVENT_EXCEPTION'
export const REMOVE_NOTE_FROM_CALL = 'REMOVE_NOTE_FROM_CALL'
export const REMOVE_NOTE_FROM_PAGE = 'REMOVE_NOTE_FROM_PAGE'
export const REMOVE_RECENT_CALL = 'REMOVE_RECENT_CALL'
export const REMOVE_SEARCH_FILTER = 'REMOVE_SEARCH_FILTER'
export const REMOVE_STATUS_FILTERS = 'REMOVE_STATUS_FILTERS'
export const REMOVE_CALL_TYPE_FILTER = 'REMOVE_CALL_TYPE_FILTER'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const REMOVE_EVENTS = 'REMOVE_EVENTS'
export const RESET = 'RESET'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_ENDS_TIME_FILTER = 'SET_ENDS_TIME_FILTER'
export const SET_STARTS_TIME_FILTER = 'SET_STARTS_TIME_FILTER'
export const SET_CALL_PROVIDER_FILTERS = 'SET_CALL_PROVIDER_FILTERS'
export const SET_CALL_TYPE_FILTERS = 'SET_CALL_TYPE_FILTERS'
export const SET_EXTERNAL_PROVIDERS = 'SET_EXTERNAL_PROVIDERS'
export const SET_FILTER_IDS = 'SET_FILTER_IDS'
export const SET_INCOMPLETE_CALL_COUNT = 'SET_INCOMPLETE_CALL_COUNT'
export const SET_INCOMPLETE_TOTAL_COUNT_ONLY = 'SET_INCOMPLETE_TOTAL_COUNT_ONLY'
export const SET_INCOMPLETE_PAGING_COUNT = 'SET_INCOMPLETE_PAGING_COUNT'
export const SET_INTERNAL_PROVIDERS = 'SET_INTERNAL_PROVIDERS'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS'
export const SET_FILTER_META = 'SET_FILTER_META'
export const SET_META = 'SET_META'
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'
export const SET_SESSION = 'SET_SESSION'
export const SET_NON_URGENT_CALL_FILTER = 'SET_NON_URGENT_CALL_FILTER'
export const SET_PENDING_CALLS_COUNT = 'SET_PENDING_CALLS_COUNT'
export const SET_PENDING_CALLS_COUNT_BY_ID = 'SET_PENDING_CALLS_COUNT_BY_ID'
export const SET_URGENT_CALL_FILTER = 'SET_URGENT_CALL_FILTER'
export const SET_USER = 'SET_USER'
export const SET_VERSION = 'SET_VERSION'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const UPDATE_CALL_NOTE = 'UPDATE_CALL_NOTE'
export const UPDATE_PAGE_NOTE = 'UPDATE_PAGE_NOTE'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const UPDATE_CALL_ATTRIBUTE = 'UPDATE_CALL_ATTRIBUTE'
export const UPDATE_META_LAST_PAGE = 'UPDATE_META_LAST_PAGE'
export const UPDATE_META_OFFSET = 'UPDATE_META_OFFSET'

export const FETCHING = 'FETCHING'
export const FETCHING_COMPLETE = 'FETCHING_COMPLETE'
export const LOADING = 'LOADING'
export const LOADING_COMPLETE = 'LOADING_COMPLETE'
export const LOCKED = 'LOCKED'
export const UNLOCK = 'UNLOCK'

export const LOCK_SCROLLING = 'LOCK_SCROLLING'
export const UNLOCK_SCROLLING = 'UNLOCK_SCROLLING'
export const UPDATE_ARIA_MESSAGE = 'UPDATE_ARIA_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
