import moment from 'moment'
import { api } from '@/store/helpers'
import actions from '@/store/helpers/actions'
import {
	ADD_NOTE_EVENT,
	ADD_NOTE_TO_PAGE,
	COMPLETE_CALL,
	REMOVE_NOTE_FROM_PAGE,
	REMOVE_STATUS_FILTERS,
	RESET_FILTERS,
	REMOVE_SEARCH_FILTER,
	SET_CALL_PROVIDER_FILTERS,
	SET_FILTER_IDS,
	SET_FILTER_META,
	SET_INCOMPLETE_PAGING_COUNT,
	SET_SEARCH_FILTER,
	SET_STATUS_FILTERS,
	UPDATE_PAGE_NOTE,
	REMOVE_DATE_RANGE_FILTER,
	SET_ENDS_TIME_FILTER,
	SET_CALL_TYPE_FILTERS,
	SET_STARTS_TIME_FILTER,
	SET_ITEMS,
} from '@/store/mutation-types'

/**
 * Make a new call note event model.
 *
 * @return {Object}
 */
const makeEvent = (note, summary) => {
	return {
		summary,
		type: 'note',
		agent: `${note.user.first_name} ${note.user.last_name}`,
		call_id: note.call_id,
		created_at: note.created_at,
		id: Math.floor(Math.random() * 100000),
		updated_at: note.updated_at,
	}
}

/**
 * The module's dispatchable actions.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	...actions,

	/**
	 * Add a new page note to a page.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async addNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(ADD_NOTE_TO_PAGE, note)

			await dispatch('addNoteCreatedEvent', note)
		}
	},

	/**
	 * Add a note created event to a page.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteCreatedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`

		const event = makeEvent(
			note,
			`${fullName} added note: "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	/**
	 * Add a note deleted event to a page.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteDeletedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`
		const format = 'YYYY-MM-DD HH:mm:ss'
		const timestamp = moment
			.utc(note.created_at, format)
			.format('MM/DD/YYYY hh:mm')

		const event = makeEvent(
			note,
			`${fullName} deleted note (created ${timestamp} UTC): "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	/**
	 * Add a note updated event to a page.
	 *
	 * @param {Function} options.commit
	 * @param {Object} note
	 * @return {void}
	 */
	addNoteUpdatedEvent({ commit }, note) {
		const fullName = `${note.user.first_name} ${note.user.last_name}`
		const format = 'YYYY-MM-DD HH:mm:ss'
		const timestamp = moment
			.utc(note.created_at, format)
			.format('MM/DD/YYYY hh:mm')

		const event = makeEvent(
			note,
			`${fullName} edited note (created ${timestamp} UTC): "${note.content}"`
		)

		commit(ADD_NOTE_EVENT, event)
	},

	/**
	 * Mark the given page sid as complete.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.rootGetters
	 * @param {String} payload.sid
	 * @param {String} payload.message
	 * @return {Response}
	 */
	async complete({ commit, rootGetters }, { sid, message }) {
		await api()
			.calls()
			.complete(sid, message || '')

		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.find(sid)

		await commit(COMPLETE_CALL, response.get('data', {}))

		return response
	},

	/**
	 * Create a new page.
	 *
	 * @param {Function} options.dispatch
	 * @param {Number} payload.provider_id
	 * @param {String} payload.callback_number
	 * @param {String} payload.patient_name
	 * @param {String} payload.patient_message
	 * @param {String} payload.patient_dob
	 * @param {Number} payload.partner_id
	 * @return {Response}
	 */
	async create({ dispatch }, payload = {}) {
		const response = await api()
			.calls()
			.create(payload)

		await dispatch('add', response.get('call'))

		return response
	},

	/**
	 * Delete a call note from a call.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async deleteNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(REMOVE_NOTE_FROM_PAGE, note)

			await dispatch('addNoteDeletedEvent', note)
		}
	},

	/**
	 * Fetch an specific page.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.rootGetters
	 * @param {String} payload.sid
	 * @return {Response}
	 */
	async find({ dispatch, rootGetters }, sid) {
		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.find(sid)

		dispatch('add', response.get('data', {}))

		return response
	},

	/**
	 * Fetch the given page of pages models.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} options.getters
	 * @param {Object} options.rootGetters
	 * @param {Number} payload.page
	 * @return {Response}
	 */
	async get({ commit, dispatch, state, getters, rootGetters }, payload = 1) {
		const page = typeof payload !== 'object' ? payload : 1

		try {
			await dispatch('setLoading', true)

			const response = await api()
				.partners(rootGetters.partner.id)
				.calls()
				.starts(state.filters.starts)
				.ends(state.filters.ends)
				.page(page)
				.pagingOnly() // Paging data only
				.providers(state.filters.providers)
				.search(state.filters.search)
				.filters(state.filters.statuses)
				.get(true)

			if (page === 1) {
				if (getters.hasFilter || getters.hasProviderFilter) {
					commit(RESET_FILTERS)
				} else {
					commit(SET_ITEMS, [])
				}
			}

			await dispatch('handleResponse', response)
		} catch (error) {
			console.error('Error fetching pages:', error)
		} finally {
			if (payload?.fromFilters && payload?.callId) {
				await dispatch('find', payload.callId)
			}
			await dispatch('setLoading', false)
		}
		return
	},

	/**
	 * Handle an API request's response.
	 *
	 * @param {Function} options.dispatch
	 * @param {Object} options.getters
	 * @param {Response} response
	 * @return {Response}
	 */
	async handleResponse({ dispatch, getters }, response) {
		await dispatch('add', response.get('data'))

		if (getters.hasFilter || getters.hasProviderFilter) {
			return dispatch('handleFilteredResponse', response)
		}

		await dispatch('setMeta', response.get('meta'))

		return response
	},

	/**
	 * Set the filters to scope any future queries by.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Array} payload.search
	 * @param {Array} payload.statuses
	 * @param {Array} payload.types
	 * @param {Boolean} payload.urgent
	 * @param {Boolean} payload.nonUrgent
	 * @return {void}
	 */
	async setFilters(
		{ commit, dispatch, state },
		{ starts, ends, providers, search, statuses, types, payload }
	) {
		if (starts && starts !== state.filters.starts) {
			commit(SET_STARTS_TIME_FILTER, starts)
		}

		if (ends && ends !== state.filters.ends) {
			commit(SET_ENDS_TIME_FILTER, ends)
		}

		if (search && search !== state.filters.search) {
			commit(SET_SEARCH_FILTER, search)
		}

		if (statuses && statuses !== state.filters.items) {
			commit(SET_STATUS_FILTERS, statuses)
		}

		if (types && types !== state.filters.types) {
			commit(SET_CALL_TYPE_FILTERS, types)
		}

		if (providers && providers !== state.filters.providers) {
			commit(SET_CALL_PROVIDER_FILTERS, providers)
		}
		return await dispatch('get', payload)
	},

	/**
	 * Remove the current date range filter.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @return {void}
	 */
	removeDateRangeFilter({ commit, state }) {
		if (state.filters.starts && state.filters.ends) {
			commit(REMOVE_DATE_RANGE_FILTER)
		}
	},

	/**
	 * Handle an API request's response containing filtered data.
	 *
	 * @param {Function} options.commit
	 * @param {Response} response
	 * @return {Response}
	 */
	handleFilteredResponse({ commit }, response) {
		commit(SET_FILTER_IDS, response.get('data'))

		commit(SET_FILTER_META, response.get('meta'))

		return response
	},

	/**
	 * Remove the current search term.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.getters
	 * @param {Object} options.state
	 * @return {void}
	 */
	removeSearchFilter({ commit, getters, state }) {
		if (!getters.hasFilter) {
			commit(RESET_FILTERS)
		}
		if (state.filters.search.length > 0) {
			commit(REMOVE_SEARCH_FILTER)
		}
	},

	/**
	 * Remove the current item from the filters.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.state
	 * @param {String} payload.filter
	 * @return {void}
	 */
	removeFilter({ commit, state }, filter) {
		if (filter && state.filters.statuses.indexOf(filter) > -1) {
			commit(REMOVE_STATUS_FILTERS, filter)
		}
	},

	/**
	 * Get the incomplete pages count statistics from the API.
	 *
	 * @param {Function} options.commit
	 * @param {Object} options.rootGetters
	 * @return {Response}
	 */
	async getIncompletePagingCount({ commit, rootGetters }) {
		const response = await api()
			.partners(rootGetters.partner.id)
			.calls()
			.pagingOnly()
			.starts(rootGetters['calls/startDateFilter'])
			.ends(rootGetters['calls/endDateFilter'])
			.count(true)

		commit(SET_INCOMPLETE_PAGING_COUNT, response.get('data', {}))

		return response
	},

	/**
	 * Update the given call note.
	 *
	 * @param {Function} options.commit
	 * @param {Function} options.dispatch
	 * @param {Object} options.state
	 * @param {Object} note
	 * @return {void}
	 */
	async updateNote({ commit, dispatch, state }, note) {
		if (state.ids.includes(note.call_id)) {
			commit(UPDATE_PAGE_NOTE, note)

			await dispatch('addNoteUpdatedEvent', note)
		}
	},

	setLoading({ commit }, value) {
		commit('SET_LOADING', value)
	},
}
